import React from 'react';
import { graphql } from "gatsby"
import SEO from '../components/seo';
import { RichText } from "prismic-reactjs"
import Img from 'gatsby-image'
import { getExcerpt } from '../core/utils'
// const image = require("../images/nelly-monnier-eric-tabuchi.jpg")

export const pageQuery = graphql`
	query QueryDefault($uid: String!) {
		prismicPage(uid: { eq: $uid }) {
			data {
				title{text},
        texte{raw, text},
        image_featured{
          url
          alt
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
        liste {
          image {
            url
            alt
            fluid(maxWidth: 1500) {
              ...GatsbyPrismicImageFluid
            }
          }
          title1 {
            text
          }
          texte1 {
            raw
          }
        }
			}
		}
	}
`

const Default = ({ data }) => {

  const {
    title,
    texte,
    image_featured,
    liste
  } = data.prismicPage.data
  // console.log(liste)
  const excerpt = getExcerpt(texte.text, 200).shortText
  return (
    <div className="default">
      <SEO
        pageTitle={title.text}
        pageDescription={excerpt || ''}
        pageImage={image_featured.url}
        // pathname=""
        page={true}
        template="template-default"
      />

      <div className="row">
        <div className="col-md-6 col-xs-12"></div>
        <div className="col-md-6 col-xs-12">
          <h1 className="strong fS">{title.text}</h1>
          <div className="texte mb2 fS">
            {RichText.render(texte.raw)}
          </div>
      
          {liste.map((li, i) => (
            <div className="row " key={i}>
              
              <div className="col-md-6 col-xs-12">
                <div className="liste-content fS">
                  {li.title1 && li.title1.text &&
                    <h2 className="strong">{li.title1.text}</h2>
                  }
                  <div className="texte mb3">
                    {RichText.render(li.texte1.raw)}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="mb1">
                  <Img {...li.image} />
                </div>
              </div>
       
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Default;